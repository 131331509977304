import { useEffect, useState } from 'react'
import { FEATURE_BLOGS_API, IMAGE_URL } from '../../../../Utilities/APIs/APIs'
import BlogCard from '../../../Layouts/BlogCard/BlogCard'

import './HomeBlog.css'
import axios from 'axios'

function HomeBlog() {

    const [featureBlogs, setFeatureBlogs] = useState(null);

    useEffect(() => {
        async function functionName() {
            const { data } = await axios.get(FEATURE_BLOGS_API)
            setFeatureBlogs(data)
        }

        functionName()
    }, [])


    return (
        <section className="home_blog">
            <div className="container">
                <h1 className="sc_title">Blogs</h1>
                <div className="grid_box">
                    {featureBlogs?.map(featureBlog => (
                        <BlogCard
                            key={featureBlog._id}
                            imgUrl={IMAGE_URL + featureBlog?.blog?.image}
                            title={featureBlog?.blog?.name}
                            // desc={featureBlog?.blog?.shortDescription}
                            desc='We believe that good people have the ability to bring out the best in others, to inspire them to act with compassion, kindness, and generosity.'
                            link={'/single-blog/' + featureBlog?.blog?._id}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default HomeBlog