import { useEffect, useState } from 'react';
import axios from 'axios';
import { BLOGS_API, IMAGE_URL } from '../../../Utilities/APIs/APIs'

import BlogCard from '../../Layouts/BlogCard/BlogCard'

import './Blogs.css'

function Blogs() {

    const [blogs, setBlogs] = useState(null);

    useEffect(() => {
        async function getBlogs() {
            const { data } = await axios.get(BLOGS_API)
            setBlogs(data)
        }

        getBlogs()
    }, [])

    return (
        <section className="blog_page page_padding">
            <div className="container">
                <h1 className="page_title">My <br /> blogs</h1>
                <div className="grid_box">
                    {blogs?.map(blog => (
                        <BlogCard
                            key={blog._id}
                            imgUrl={IMAGE_URL + blog?.image}
                            title={blog?.name}
                            // desc={blog?.shortDescription}
                            desc='We believe that good people have the ability to bring out the best in others, to inspire them to act with compassion, kindness, and generosity.'
                            link={'/single-blog/' + blog?._id}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Blogs