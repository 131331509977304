import { IMAGE_URL, SLIDERS_API } from '../../../../Utilities/APIs/APIs';
import { useEffect, useState } from 'react';
import axios from 'axios';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// Import own styles
import './BannerSlider.css'

function BannerSlider() {

    const [sliders, setSliders] = useState(null);
    console.log("🚀 ~ file: BannerSlider.js:22 ~ BannerSlider ~ sliders:", sliders)

    useEffect(() => {
        async function getBannerSlider() {
            const { data } = await axios.get(SLIDERS_API)
            setSliders(data)
        }

        getBannerSlider()
    }, [])


    return (
        <section className="banner_slider_wrapper">
            <Swiper
                // autoplay={{
                //     delay: 3000,
                //     disableOnInteraction: false,
                // }}
                loop={true}
                modules={[Autoplay]}
                className="banner_slider"
            >
                {sliders?.map(slider => (
                    <SwiperSlide key={slider._id}>
                        <div className="swiper-slide" >
                            <img className="lg_img" src={IMAGE_URL + slider?.image} alt="thumb" />
                            <img className="sm_img" src={IMAGE_URL + slider?.image1} alt="thumb" />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    )
}

export default BannerSlider