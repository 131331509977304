import { useState } from 'react'
import VideoImg1 from '../../../assets/images/video-img-1.png'
import VideoImg2 from '../../../assets/images/video-img-2.png'
import VideoImg3 from '../../../assets/images/video-img-3.png'
import VideoPlayer from '../../Elements/VideoPlayer/VideoPlayer'
import Modal from '../../Layouts/Modal/Modal'

import VideoCard from './VideoCard/VideoCard'

import './VideoGallery.css'

function VideoGallery() {

    const [showPlayer, setShowPlayer] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const handleSetUrl = (url) => {
        console.log(url)
        setVideoUrl(url);
        setShowPlayer(true);
    }
    const handleModalClose = (url) => {
        setVideoUrl('');
        setShowPlayer(false);
    }


    return (
        <>
            <section className="video_page page_padding">
                <div className="container">
                    <h1 className="page_title">My video <br /> gallery</h1>
                    <div className="grid_box">
                        <VideoCard
                            handleClick={() => handleSetUrl('qNSv9I0Flac')}
                            imgUrl={VideoImg1}
                            title='Lost in the Stars: A Cosmic Journey Music Video'
                        />
                        <VideoCard
                            handleClick={() => handleSetUrl('uZFfNMfHHCA')}
                            imgUrl={VideoImg2}
                            title='Neon Nights: An 80s-Inspired Synthwave Music Video'
                        />
                        <VideoCard
                            handleClick={() => handleSetUrl('qNSv9I0Flac')}
                            imgUrl={VideoImg3}
                            title='Lost in the Stars: A Cosmic Journey Music Video'
                        />
                        <VideoCard
                            handleClick={() => handleSetUrl('uZFfNMfHHCA')}
                            imgUrl={VideoImg2}
                            title='Neon Nights: An 80s-Inspired Synthwave Music Video'
                        />
                        <VideoCard
                            handleClick={() => handleSetUrl('qNSv9I0Flac')}
                            imgUrl={VideoImg3}
                            title='Lost in the Stars: A Cosmic Journey Music Video'
                        />
                        <VideoCard
                            handleClick={() => handleSetUrl('uZFfNMfHHCA')}
                            imgUrl={VideoImg2}
                            title='Neon Nights: An 80s-Inspired Synthwave Music Video'
                        />
                    </div>
                </div>
            </section>
            {showPlayer &&
                <Modal
                    handleClose={handleModalClose}
                >
                    <VideoPlayer
                        videoUrl={videoUrl}
                    />
                </Modal>
            }
        </>
    )
}

export default VideoGallery