import { useState } from 'react'
import SvideoImg from '../../../../assets/images/video-img-1.png'
import VLImg1 from '../../../../assets/images/video-img-1.png'

import SingleVideoCard from './SingleVideoCard/SingleVideoCard'
import VideoListCard from './VideoListCard/VideoListCard'

import './HomeVideo.css'
import Modal from '../../../Layouts/Modal/Modal'
import VideoPlayer from '../../../Elements/VideoPlayer/VideoPlayer'

function HomeVideo() {

    const [showPlayer, setShowPlayer] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const handleSetUrl = (url) => {
        console.log(url)
        setVideoUrl(url);
        setShowPlayer(true);
    }
    const handleModalClose = (url) => {
        setVideoUrl('');
        setShowPlayer(false);
    }

    return (
        <>
            <section className="home_video">
                <div className="container">
                    <div className="grid_box">
                        <div className="grid_item">
                            <h1 className="sc_title">My Video <br /> <span>Gallery</span></h1>
                            <SingleVideoCard
                                event={() => handleSetUrl('qNSv9I0Flac')}
                                imgUrl={SvideoImg}
                                date='16 March, 2022'
                                title='Nam Elit Agna, Endrerit Sit Amet, Tincidunt Ac, Viverra'
                            />
                        </div>
                        <div className="grid_item">
                            <VideoListCard
                                event={() => handleSetUrl('uZFfNMfHHCA')}
                                imgUrl={VLImg1}
                                date='16 March, 2022'
                                title='Nam Elit Agna, Endrerit Sit Amet, Tincidunt Ac, Viverra'
                            />
                            <VideoListCard
                                event={() => handleSetUrl('qNSv9I0Flac')}
                                imgUrl={VLImg1}
                                date='16 March, 2022'
                                title='Nam Elit Agna, Endrerit Sit Amet, Tincidunt Ac, Viverra'
                            />
                            <VideoListCard
                                event={() => handleSetUrl('uZFfNMfHHCA')}
                                imgUrl={VLImg1}
                                date='16 March, 2022'
                                title='Nam Elit Agna, Endrerit Sit Amet, Tincidunt Ac, Viverra'
                            />
                            <VideoListCard
                                event={() => handleSetUrl('qNSv9I0Flac')}
                                imgUrl={VLImg1}
                                date='16 March, 2022'
                                title='Nam Elit Agna, Endrerit Sit Amet, Tincidunt Ac, Viverra'
                            />
                            <VideoListCard
                                event={() => handleSetUrl('uZFfNMfHHCA')}
                                imgUrl={VLImg1}
                                date='16 March, 2022'
                                title='Nam Elit Agna, Endrerit Sit Amet, Tincidunt Ac, Viverra'
                            />
                        </div>
                    </div>
                </div>
            </section>
            {showPlayer &&
                <Modal
                    handleClose={handleModalClose}
                >
                    <VideoPlayer
                        videoUrl={videoUrl}
                    />
                </Modal>
            }
        </>
    )
}

export default HomeVideo